import * as React from "react"
import { ApolloProvider } from "@apollo/client"
import { ChakraProvider, DarkMode } from "@chakra-ui/react"
import { theme } from "@kunstbende/theme"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import dayjs from "dayjs"
import type { NextPage } from "next"
import type { AppProps } from "next/app"
import Head from "next/head"
import Script from "next/script"
import "../styles/global.css"
import "../styles/jodit.css"
import "dayjs/locale/nl"
import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
dayjs.locale("nl")

const queryClient = new QueryClient()

import { useApollo } from "lib/apollo/client"
import { Analytics } from "components/Analytics"

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}
export default function KunstbendeApp(props: AppPropsWithLayout) {
  const { Component, pageProps } = props

  const handleHideNewsletter = () => {
    const elements = document.getElementsByClassName("eprov-cs")
    if (elements.length === 0) return
    elements[0].removeAttribute("style") // reset style attr
    elements[0].setAttribute("style", "display: none !important;") // hide modal loaded by script
    document.body.removeAttribute("style") // remove scroll blocking styling applied by the script

    // // global function name can be found by going to https://app.laposta.nl/c.listconfig/s.memberforms/t.subscribe
    // // finding the aanmeldenformulieer, configuration, then in the settings you will at the bottom
    // // (it's also just a combo of the script src url)

    // // WARNING: this causes an error in prod, leaving it in as reference until I find a better solution
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._wdbxbxpdd240c4luiual.action('hide');
  }

  const apolloClient = useApollo(pageProps.initialApolloState)
  const getLayout = Component.getLayout ?? ((page) => page)
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <meta name="msapplication-TileColor" content="#fdd92d" />
        <meta name="theme-color" content="#fdd92d" />
        <script async src="http://www.instagram.com/embed.js" type="text/javascript" />
        {/* Importing globalThis to stop type errors in Sentry: https://github.com/vercel/next.js/discussions/49771 */}
        <script src="https://unpkg.com/@ungap/global-this@0.4.4/min.js" noModule async />
      </Head>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={apolloClient}>
            <Analytics />
            <Script
              src="https://consent.cookiebot.com/uc.js"
              data-cbid="3bbae292-4d1a-42c1-b789-ab5590083e5b"
              data-blockingmode="auto"
              strategy="afterInteractive"
            />
            <Script
              // src can be found by going to https://app.laposta.nl/c.listconfig/s.memberforms/t.subscribe, aanmeldenformulieer
              src="https://embed.email-provider.eu/e/wdbxbxpdd2-40c4luiual.js"
              strategy="beforeInteractive"
              onReady={handleHideNewsletter}
            />
            <DarkMode>{getLayout(<Component {...pageProps} />)}</DarkMode>
          </ApolloProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </>
  )
}
